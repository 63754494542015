<template>
  <div id="step1">
    <div id="first_row" class="row justify-content-center align-items-center">
      <PelotitasPasos :infostep="'Datos personales'" />
    </div>
    <div class="loader" v-if="preload2">
        <Loader/>
    </div>
    <fieldset v-else>
      <div id="fourth_row_form" class="row justify-content-center pt-2 d-none d-md-flex hidden-md-and-down">
        <div class="col-10 col-sm-7 col-md-11 col-lg-10 col-xl-10">
          <div class="row justify-content-center">
            <p class="text-center slogan1">Esta propuesta de préstamo es gratis y sin compromiso</p>
          </div>
        </div>
      </div>
      <div id="cards_row_form" class="row justify-content-center pt-2">
        <div class="col-10 col-sm-10 col-md-12 col-lg-6 col-xl-6">
          <div class="row justify-content-center">
            <p class="text-center" style="font-size: 20px; font-weight: 700; font-family: 'Montserrat-Bold'">Datos personales</p>
          </div>

          <div class="row justify-content-center mt-0">
            <div class="col-12 col-md-6 col-lg-6 p-2">
              <input type="text" :class="{ error: errors.nombre }" name="nombre" @blur="comprobarNombre()" id="nombre" class="ibn_input" placeholder="Nombre" v-model="formulario.nombre" />
              <p v-if="errors.nombre && formulario.nombre.length == 0" class="error">Debe rellenar este campo...</p>
              <p v-if="errors.nombre && formulario.nombre.length > 0 && formulario.nombre.length <= 2" class="error">Debe tener mínimo 3 carácteres</p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 p-2">
              <input type="text" :class="{ error: errors.dni_nif }" name="dni_nif" @blur="comprobarDNI()" id="dni_nif" class="ibn_input" placeholder="DNI" maxlength="9" v-model="formulario.dni_nif" />
              <p v-if="errors.dni_nif" class="error">El DNI introducido no es válido...</p>
            </div>
            <div class="col-12 col-md-6 p-2 autocompletar">
              <input id="codigo_postal" type="number" :class="{ error: errors.codigo_postal }" name="codigo_postal" @keyup="limitLengthCP()" @blur="comprobarCP()"  class="ibn_input" placeholder="Código postal" max="99999" v-model="formulario.codigo_postal" />
              <p v-if="errors.codigo_postal" class="error">El código postal no es válido...</p>
            </div>
            <div class="col-12 col-md-6 p-2">
              <input type="tel" :class="{ error: errors.telefono }" min="0" max="999999999" @blur="comprobarTelefono()" name="telefono" id="telefono" class="ibn_input" placeholder="Teléfono" maxlength="9" v-model="formulario.telefono" />
              <p v-if="errors.telefono" class="error">Debe ser un teléfono válido...</p>
            </div>

            <div class="col-12 col-md-12 p-2">
              <input type="text" :class="{ error: errors.email }" name="email" id="email" @blur="comprobarEmail()" class="ibn_input" placeholder="ejemplo@gmail.com" v-model="formulario.email" />
              <p v-if="errors.email" class="error">Debe ser un email válido...</p>
            </div>
            <div class="col-12 pt-4">
              <label class="checkbox_label" for="aceptoAll">
                <input type="checkbox" id="aceptoAll" class="checkbox" name="legal_advice" @click="marcarCheckBoxs($event)" />
                <span class="checkbox_text pl-2 font-weight-bold">Seleccionar / deseleccionar todo</span>
              </label>
            </div>
            <div class="col-12 text-center pt-4 pb-3">
              <button type="button" class="btn-orange" @click="comprobarDatos()">Solicitar préstamo</button>
            </div>
            <div class="col-12 pt-4">
              <label class="checkbox_label" for="acepto1">
                <input type="checkbox" id="acepto1" class="checkbox" name="legal_advice" v-model="formulario.acepto1" @click="marcarCheckBoxs($event)" />
                <span class="checkbox_text pl-2">He leído el <strong><router-link to="/aviso-legal" target="_blank">Aviso Legal</router-link></strong> y acepto la <strong><router-link to="/politica-privacidad" target="_blank">Política de Privacidad</router-link></strong>.</span>
              </label>
              <p v-if="errors.check1" class="error">Debe marcar este campo...</p>
            </div>
            <div class="col-12 pt-4 ">
              <label class="checkbox_label" for="acepto2">
                <input type="checkbox" id="acepto2" class="checkbox" name="legal_advice" v-model="formulario.acepto2" @click="marcarCheckBoxs($event)" />
                <span class="checkbox_text pl-2">Acepto recibir publicidad de <strong>IBANCAR WORLD S.L.</strong> y terceros en base a mis intereses. {{ leerMas ? 'Es posible darse de baja en cualquier momento. ' : '' }}</span>
              </label>
              <small @click="leerMas = !leerMas" class="verMas pb-1" :class="leerMas ? 'verMenos' : ''">{{ leerMas ? "Leer menos" : "Leer más" }}</small>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div id="separator_row_form" class="row justify-content-around ">
      <div class="col-10 col-sm-10 col-md-8 col-lg-6">
        <hr />
        <p class="text-center" style="font-family: Montserrat-Bold">Más de 6.000 préstamos concedidos</p>
      </div>
    </div>

    <div id="images_row_form" class="row justify-content-center">
      <div class="col-10 col-sm-7 col-md-8 col-lg-7 col-xl-6">
        <div class="card-columns">
          <div class="card">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_dinero.svg" alt="dinero" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Dinero en cuenta en el mismo día</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_casa.svg" alt="casa" />
              </div>
              <div class="col-9">
                <span class="text-center feature">100% online sin salir de casa</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_documentacion.svg" alt="documentacion" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Cancela el préstamo cuando quieras</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row justify-content-start align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_tarta.svg" alt="tarta" />
              </div>
              <div class="col-8 col-sm-9">
                <span class="text-center feature">Sin alquiler y amortiza desde la primera cuota</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="d-flex justify-content-start align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_calendario.svg" alt="calendario" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Hasta 2 carencias al año</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="d-flex justify-content-start align-items-center">
              <div class="col-3">
                <img src="../../assets/img/image_titularidad.svg" alt="titularidad" />
              </div>
              <div class="col-9">
                <span class="text-center feature">Sin cambio de titularidad</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center pt-4 pb-3">
          <button v-if="!preload2" type="button" class="btn-orange" @click="comprobarDatos()">Solicitar préstamo</button>
        </div>
      </div>
    </div>

    <BotonVolver />
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapState } from "vuex";
import PelotitasPasos from "./PelotitasPasos.vue";
import BotonVolver from "./BotonVolver.vue";
import Loader from '@/components/Loader.vue'
import { useMeta } from 'vue-meta';

window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

function gtag() {
  window.dataLayer.push(arguments);
}
function gtag_report_conversion() {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: "AW-873880947/Pb39CNC13nAQ87rZoAM",
    event_callback: callback,
  });
  return false;
}

function gtag_report_conversion_ko() {
  var callback = function () {};
  gtag("event", "conversion", {
    send_to: 'AW-873880947/CLQaCLPFzPAYEPO62aAD',
    value: 1.0,
    currency: 'EUR',
    event_callback: callback,
  });
  return false;
}

export default {
  name: "NewStep3",
  data: function () {
    return {
      preload2: false,
      publicPath: process.env.BASE_URL,
      leerMas: false,
      provincias: [],
      windowWidth: window.innerWidth,
      errors: {
        nombre: false,
        dni: false,
        email: false,
        telefono: false,
        check1: false,
        check2: false,
        codigo_postal: false,
      },
    };
  },
  created() {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG

    window.dataLayer.push({
      event: "datos_personales",
      idAfiliado: this.afiliado,
    });
  },
  setup() {
    useMeta({
      meta: [
        {name:'robots', content:'noindex'},
      ]
    })
  },
  store,
  computed: {
    ...mapState({
      step: (state) => state.formulario.step,
      data_matricula: (state) => state.formulario.data_matricula,
      formulario: (state) => state.formulario,
      preload: (state) => state.preload,
      afiliado: (state) => state.afiliado,
      api_url: (state) => state.api_url
    }),
    catalogo_provincias: function () { //Para que TypeAhead pueda procesar los datos es necesario normalizarlos antes
      return this.provincias.flatMap((provincias) => [provincias.provincia.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), provincias.provincia.normalize("NFD").match(/[\u0300-\u036f]/g) ? provincias.provincia : ""]);
    },
  },
  components: {
    PelotitasPasos,
    BotonVolver,
    Loader
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    marcarCheckBoxs(event) { //LOGICA DE CHECKS PARA QUE SE SELECCIONE/DESELECCIONE TODO AL PULSAR EL CHECK CORRESPONDIENTE
      const checkbox_name = event.target.id;
      let aceptoAll = document.getElementById("aceptoAll");
      let acepto1 = document.getElementById("acepto1");
      let acepto2 = document.getElementById("acepto2");
      switch (checkbox_name) {
        case "aceptoAll":
          if (aceptoAll.checked) {
            acepto1.checked = true;
            acepto2.checked = true;
            store.commit("setChecksAcepto", true);
          } else {
            acepto1.checked = false;
            acepto2.checked = false;
            store.commit("setChecksAcepto", false);
          }
          break;
        case "acepto1":
          if (acepto1.checked & acepto2.checked) {
            aceptoAll.checked = true;
            store.commit("setCheckAcepto1", true);
          } else {
            aceptoAll.checked = false;
            store.commit("setCheckAcepto1", false);
          }
          break;
        case "acepto2":
          if (acepto1.checked & acepto2.checked) {
            aceptoAll.checked = true;
            store.commit("setCheckAcepto2", true);
          } else {
            aceptoAll.checked = false;
            store.commit("setCheckAcepto2", false);
          }
          break;
      }
    },
    comprobarCP() {
        // Sacamos longitud para comprobar que haya rellenado al menos 4-5 campos (aunque 0 a la izquierda no cuentan)
        const length = this.formulario.codigo_postal.toString().length;
      if (length == 0 || (length != 4 && length != 5)) {
        this.errors.codigo_postal = true;
        document.getElementById("codigo_postal").style.border = "1px solid #ff000080";
        document.getElementById("codigo_postal").style.borderLeft = "7px solid #ff000080";
        return false;
      } else {
        this.errors.codigo_postal = false;
        document.getElementById("codigo_postal").style.border = "1px solid green";
        document.getElementById("codigo_postal").style.borderLeft = "7px solid green";
        return true;
      }
    },
    limitLengthCP(){
      const length = this.formulario.codigo_postal.toString().length;
      if (length > 4) {
        this.formulario.codigo_postal = this.formulario.codigo_postal.toString().slice(0,5); 
      }
    },
    comprobarNombre() {
      if (this.formulario.nombre == "") {
        this.errors.nombre = true;
        return false;
      } else if (this.formulario.nombre != "" && this.formulario.nombre.length <= 2) {
        this.errors.nombre = true;
        return false;
      } else {
        this.errors.nombre = false;
        document.getElementById("nombre").style.border = "1px solid green";
        document.getElementById("nombre").style.borderLeft = "7px solid green";
        return true;
      }
    },
    comprobarDNI(){
      var numero, letr, letra;
      var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/i;

      var dni = this.formulario.dni_nif.toUpperCase();

      if(expresion_regular_dni.test(dni) === true){
          numero = dni.substr(0,dni.length-1);
          numero = numero.replace('X', 0);
          numero = numero.replace('Y', 1);
          numero = numero.replace('Z', 2);
          letr = dni.substr(dni.length-1, 1);
          numero = numero % 23;
          letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
          letra = letra.substring(numero, numero+1);
          if (letra != letr) {
            this.errors.dni_nif = true;
            return false;
          }else{
            this.errors.dni_nif = false;
            document.getElementById("dni_nif").style.border = "1px solid green";
            document.getElementById("dni_nif").style.borderLeft = "7px solid green";
            return true;
          }
      }else{
        this.errors.dni_nif = true;
        return false;
      }
    },
    comprobarEmail() {
      if (!this.validateEmail(this.formulario.email)) {
        this.errors.email = true;
        return false;
      } else {
        this.errors.email = false;
        document.getElementById("email").style.border = "1px solid green";
        document.getElementById("email").style.borderLeft = "7px solid green";
        return true;
      }
    },
    validateEmail(mail) {
      var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (pattern.test(mail)) {
        return true;
      } else if (mail == "") {
        return false;
      }
      return false;
    },
    comprobarTelefono() {
      if (!this.validatePhone(this.formulario.telefono)) {
        this.errors.telefono = true;
        return false;
      } else {
        this.errors.telefono = false;
        document.getElementById("telefono").style.border = "1px solid green";
        document.getElementById("telefono").style.borderLeft = "7px solid green";
        return true;
      }
    },
    validatePhone(phone) {
      let pattern = /^\d{9}$/;
      if (pattern.test(phone)) {
        return true;
      }
      return false;
    },
    comprobarChecks() {
      this.errors.check1 = this.formulario.acepto1 ? false : true;
      return !this.errors.check1;
    },
    comprobarDatos() { //comprobamos datos antes de guardar la solicitud
      // store.commit("setPreload", true);
      this.preload2 = true;
      const telefono = this.comprobarTelefono();
      const email = this.comprobarEmail();
      const dni = this.comprobarDNI();
      const nombre = this.comprobarNombre();
      const checks = this.comprobarChecks();
      const codigo_postal = this.comprobarCP();
      if (telefono && email && dni && nombre && checks && codigo_postal) {
        this.registrarSolicitud();
      }else{
        this.preload2 = false;
      }
    },
    getYear(fecha) {
      let date = new Date(fecha);
      return date.getFullYear();
    },

    async registrarSolicitud() {
      gtag("config", "AW-873880947");
      // window.scrollTo(0, document.getElementById("formulario").scrollTop);
      let url_origen = localStorage.getItem("url_origen") ? localStorage.getItem("url_origen") : this.formulario.url_origen;
      this.cancelTokenSource = axios.CancelToken.source();

      let data = {
        cancelToken: this.cancelTokenSource.token,
        dninif: this.formulario.dni_nif,
        name: this.formulario.nombre,
        phone: this.formulario.telefono,
        email: this.formulario.email,
        zip_code: this.formulario.codigo_postal,
        car_km: this.formulario.kms,
        car_year_registration: this.getYear(this.formulario.data_matricula.fecha_matriculacion),
        car_number_plate: this.formulario.matricula.join(""),
        salary: this.formulario.ingresos,
        financed: this.formulario.financiacion,
        financed_type: this.formulario.coche_financiacion_tipo,
        titular: this.formulario.titular,
        importe: this.formulario.dinero,
        legal_advice: this.formulario.acepto1,
        lopd: this.formulario.acepto2,
        url_origen: url_origen,
        data_matricula: true,
      };

      this.formulario.loading = true;

        if (this.formulario.loading == true) {
          store.commit("setBarraColor2", "azul");
          store.commit("setCheckColor3", "azul-3");
          this.cancelTokenSource.cancel();
          data.data_matricula = false;
          await axios
            .post(this.api_url + "createSolicitudApiNuevoFlujo", data)
            .then((data) => {
              this.formulario.loading = false;
              this.preload2 = false;
              let tokenEnlace = data.data.token_enlace;
              let solicitud = data.data.solicitud;
              let idSolicitud = tokenEnlace.slice(14);
              store.commit("setTokenEnlace", tokenEnlace);
              store.commit("setIdSolicitud", idSolicitud);
              store.commit("setSolicitud", solicitud);

              if (solicitud.numeroestado >= 9000) {
                store.commit("setStep", 10);
                this.$router.push({
                  path: "/solicitud/denegada/" + tokenEnlace,
                  hash: null,
                });
                return gtag_report_conversion_ko();
              } else {
                if (solicitud.coche_marca === "") {
                  this.$router.push({
                    path: "/solicitud/coche/" + tokenEnlace,
                    hash: null,
                  });
                } else {
                  store.commit("setStep", 5);
                  store.commit("setPreload", true);
                  this.$router.push({
                    path: "/formulario/" + tokenEnlace,
                    hash: null,
                  });
                }
              }
            }).then(() => sessionStorage.clear())
            .catch(() => {
              this.formulario.loading = false;
              store.commit("setStep", 3);
            });
        }

      return gtag_report_conversion();
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    },
    formulario(value) {
      console.log(value)
    }
  },
  mounted: function () {
    store.commit("setStep", "3");
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "azul");
    store.commit("setCheckColor2", "azul-2");
    store.commit("setBarraColor2", "azul");
    store.commit("setCheckColor3", "azul-3");
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
};
</script>
<style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }
}
 #footer-mobile{ // No queremos que se vea el footer de primeras en mobile, añadiremos un margin adaptado a cada pantalla del form

  margin-top: 0px;
 }
</style>
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.loader{
        height: calc(100vh - 410px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F2F9FF;
    }

fieldset {
  margin: 5vh 15% 5vh 15%;
  padding: 2%;
  border: 1px #b3dcff solid;
}
.autocompletar:deep() .simple-typeahead > input[data-v-f81ca714] {
  width: 100%;
  padding: 10px 20px;
  height: 48px;
  line-height: 22px;
  font-size: 18px;
  border-radius: 6px;
  background: white;
  border: solid 1px #c4c4c4;
  opacity: 0.6;
}

#step1 {
  height: 100%;
  .select,
  .ibn_input {
    width: 100%;
    padding: 10px 20px;
    height: 48px;
    line-height: 22px;
    font-size: 18px;
    border-radius: 6px;
    background: white;
    border: solid 1px #c4c4c4;
    opacity: 0.6;
  }
  .checkbox_label, a {
    display: inline;
    align-items: center;
    color: #004680;
    font-size: small;
  }
  .checkbox {
    width: 1.6em;
    height: 1.6em;
    background: #fff;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #004680;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .checkbox:checked {
    background-image: url("../../assets/img/checked_icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }
  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }
  #cards_row_form {
    margin-top: 20px;
  }
  .card {
    background-color: white;
    border: none;
  }
  .verMas {
    color: #ffa500;
    font-weight: 600;
    cursor: pointer;
  }
  .feature {
    font-family: "Montserrat-Bold";
    font-size: 14px;
  }
}

@include media-breakpoint-only(xs) {
  .btn-orange{
    font-size: 18px !important;
  }
  #cards_row_form {
    margin-top: 0px !important;
  }
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}
@include media-breakpoint-only(sm) {
  fieldset {
    margin: 0;
    padding: 2%;
    border: none;
  }
  #step1 {
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }

    .card-columns {
      column-count: 1;
      max-width: 100%;
      width: 100%;
    }
    .card {
      width: 100%;
      height: auto;
    }
    .card:hover {
      width: 100%;
      height: auto;
    }
  }
}

@include media-breakpoint-only(md) {
  #step1 {
    fieldset {
      margin: 6%;
      padding: 1%;
      border: none;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
    .slogan1 {
      color: #004680;
      font-weight: 800;
    }
  }
}

@include media-breakpoint-only(lg) {
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }

    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}

@include media-breakpoint-only(xl) {
  #step1 {
    margin-top: 95px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    .card-columns {
      column-count: 2;
      max-width: 100%;
      width: 99%;
    }
    .card {
      height: 90px;
    }
  }
}
</style>
