<template>
  <div id="step1" :class="this.afiliado != '04' ? 'margin-promo' : '' ">
    <div id="first_row" class="row justify-content-center align-items-center ">
      <PelotitasPasos :infostep="'Datos del vehículo'" />
    </div>
    <fieldset :class="{ cambio_paso: transicion }">
      <div class="row justify-content-center pt-2 d-none d-lg-flex hidden-md-and-down">
        <div class="col-10 col-sm-7 col-md-6 col-lg-10 col-xl-10">
          <div class="row justify-content-center">
            <div class="text-center">
              <img src="../../../public/avatar_58.png" class="rounded image-icon" />
            </div>
          </div>
        </div>
      </div>
      <div id="fourth_row_form" @click="focusInputMatricula()" class="row justify-content-center pt-2">
        <div class="col-10 col-sm-7 col-md-9 col-lg-10 col-xl-10">
          <div class="row justify-content-center">
            <p class="text-center slogan1">Recuerda que debes tener un coche para poder solicitar este préstamo.</p>
          </div>
          <div class="row justify-content-center">
            <p class="text-center slogan2">Matrícula</p>
          </div>
        </div>
      </div>
      <div id="fith_row_form" @click="focusInputMatricula()" class="row justify-content-center pt-3">
        <div class="col-10 col-sm-7 col-md-7 col-lg-7 col-xl-4">
          <div id="container_input_matricula" class="center">
            <div class="container_spans">
              <span v-if="matricula != ' '" v-text="formulario.matricula[0]"></span>
              <span v-if="matricula != ' '" v-text="formulario.matricula[1]"></span>
              <span v-if="matricula != ' '" v-text="formulario.matricula[2]"></span>
              <span v-if="matricula != ' '" v-text="formulario.matricula[3]"></span>
              <span v-if="matricula != ' '" v-text="formulario.matricula[4]"></span>
              <span v-if="matricula != ' '" v-text="formulario.matricula[5]"></span>
              <span v-if="matricula != ' '" v-text="formulario.matricula[6]"></span>

              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="1"></span>
              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="2"></span>
              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="3"></span>
              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="4"></span>
              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="'B'"></span>
              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="'B'"></span>
              <span v-if="matricula == ' '" class="placeholder_matricula" v-text="'B'"></span>
            </div>

            <input :class="{ error: errors.matricula }" ref="matricula" autocomplete="off" @blur="comprobarMatricula()" class="matricula" id="matricula" type="text" v-model="matricula" @keyup.enter="buscarMatricula" />

            <svg width="28" height="60" viewBox="0 0 28 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 5C0 2.23858 2.23858 0 5 0H28V60H5C2.23858 60 0 57.7614 0 55V5Z" fill="#0E5D98" />
              <path d="M13.9278 5.61523L14.2576 6.62999H15.3249L14.4615 7.25713L14.7912 8.27189L13.9278 7.64436L13.0646 8.27189L13.3944 7.25713L12.5308 6.62999H13.598L13.9278 5.61523Z" fill="#F8D12E" />
              <path d="M13.9278 24.7712L14.2576 25.7865H15.3249L14.4615 26.4136L14.7912 27.4283L13.9278 26.8012L13.0646 27.4283L13.3944 26.4136L12.5308 25.7865H13.598L13.9278 24.7712Z" fill="#F8D12E" />
              <path d="M19.1835 7.31323L19.5131 8.32822H20.5803L19.7169 8.95576L20.0467 9.97052L19.1835 9.34298L18.3201 9.97052L18.6498 8.95576L17.7866 8.32822H18.8539L19.1835 7.31323Z" fill="#F8D12E" />
              <path d="M22.7721 10.8521L23.1017 11.8668H24.1689L23.3055 12.4942L23.6353 13.5089L22.7721 12.8818L21.9086 13.5089L22.2384 12.4942L21.375 11.8668H22.442L22.7721 10.8521Z" fill="#F8D12E" />
              <path d="M22.8566 19.7937L23.1861 20.8089H24.2533L23.39 21.436L23.7199 22.4505L22.8566 21.8236L21.9931 22.4505L22.3229 21.436L21.4595 20.8089H22.5267L22.8566 19.7937Z" fill="#F8D12E" />
              <path d="M5.21131 10.8521L5.54089 11.8668H6.60793L5.74472 12.4942L6.07429 13.5089L5.21131 12.8818L4.34792 13.5089L4.67767 12.4942L3.81445 11.8668H4.88173L5.21131 10.8521Z" fill="#F8D12E" />
              <path d="M23.6031 15.1162L23.9327 16.131H24.9999L24.1365 16.7581L24.4663 17.7731L23.6031 17.1462L22.7397 17.7731L23.0697 16.7581L22.2061 16.131H23.2731L23.6031 15.1162Z" fill="#F8D12E" />
              <path d="M4.39702 15.1162L4.7266 16.131H5.79365L4.93026 16.7581L5.26024 17.7731L4.39702 17.1462L3.53358 17.7731L3.86339 16.7581L3 16.131H4.06722L4.39702 15.1162Z" fill="#F8D12E" />
              <path d="M5.09722 19.7937L5.4268 20.8089H6.49384L5.63068 21.436L5.96021 22.4505L5.09722 21.8236L4.23383 22.4505L4.56358 21.436L3.7002 20.8089H4.76747L5.09722 19.7937Z" fill="#F8D12E" />
              <path d="M19.3389 23.4836L19.6685 24.4984H20.7358L19.8722 25.1259L20.2021 26.1407L19.3389 25.5136L18.4753 26.1407L18.8053 25.1259L17.9419 24.4984H19.0092L19.3389 23.4836Z" fill="#F8D12E" />
              <path d="M8.84021 7.31323L9.16973 8.32822H10.237L9.37362 8.95576L9.7036 9.97052L8.84021 9.34298L7.977 9.97052L8.30675 8.95576L7.44336 8.32822H8.51041L8.84021 7.31323Z" fill="#F8D12E" />
              <path d="M8.45056 23.4187L8.78031 24.4339H9.84759L8.9842 25.0608L9.31395 26.0756L8.45056 25.4489L7.58735 26.0756L7.9171 25.0608L7.05371 24.4339H8.12076L8.45056 23.4187Z" fill="#F8D12E" />
              <path d="M8.45508 53V38.6836H19.0703V41.1055H11.3457V44.2793H18.5332V46.6914H11.3457V50.5879H19.3438V53H8.45508Z" fill="#EAEAEA" />
            </svg>
          </div>
          <p v-if="errors.matricula" class="error text-center">Parece que la matrícula es incorrecta...</p>
        </div>
      </div>

      <div id="sixth_row_form" class="row justify-content-center pt-5 pb-4">
        <div id="boton_continuar" class="col-12">
          <button :disabled="buttonEnabled() === 'disabled'" class="btn-orange center mb-4" @click="buscarMatricula()">
            Continuar
            <svg style="margin-left: 15px" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.53636 0L0 1.48571L5.04805 6.36735L0 11.249L1.53636 12.7347L8.12078 6.36735L1.53636 0Z" fill="white" />
            </svg>
          </button>
          <p class="text-center m-0">La solicitud es gratis y sin compromiso.</p>
        </div>
      </div>
    </fieldset>
    <div id="fourth_row_form" class="row justify-content-center pt-2 d-none d-md-flex hidden-md-and-down">
      <div class="col-12">
        <div class="row justify-content-center">
          <p class="text-center slogan1">- El único préstamo con el coche como garantía -</p>
        </div>
      </div>
    </div>
    <!--
      #NF
      Este boton es diferente al componente normal, debido a que necesitamos que la pagina anterior se recague
      Al hacer un push route no conseguimos ese efecto
    -->
    <div id="volver_row_form" class="row justify-content-around pt-4">
      <div class="col-10 mt-5">
        <p class="text-left"><a class="volver_circle" href="/">&lt;</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import PelotitasPasos from "./PelotitasPasos.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "NewStep1",
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      matricula: " ",
      transicion: false,
      windowWidth: window.innerWidth,
      errors: {
        matricula: false,
      },
    };
  },
  setup() {
    useMeta({
      meta: [
        {name:'robots', content:'noindex'},
      ]
    })
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    data_matricula: (state) => state.formulario.data_matricula,
    formulario: (state) => state.formulario,
    afiliado: (state) => state.afiliado,
  }),
  components: { PelotitasPasos },
  methods: {
    back() {
      this.$router.go(-1);
    },
    buttonEnabled: function () {
      if (this.formulario.matricula.length != 7) {
        return "disabled";
      }
      return true;
    },
    escribirMatricula: function () {
      this.formulario.matricula = this.matricula.trim().toUpperCase().split("");
    },
    focusInputMatricula: function () {
      this.$refs.matricula.focus();
    },
    buscarMatricula: function () {
      this.validarFormulario();
      if (this.validarFormulario()) {
        store.commit("setMatricula",this.formulario.matricula);
        store.commit("setStep", "1a");
        this.$router.push("step1a");
        window.scrollTo(0, document.getElementById("formulario").scrollTop);
        this.transicion = true;
      }
    },
    validateAllMatricula: function (valor) {
      //Validamos que la matricula este compuesta por alfanumericos. TODO: EVITAR LA ESCRITURA DE ESPACIOS EN BLANCO
      let valid_numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      let valid_letters = ["B", "C", "D", "F", "G", "H", "J", "K", "L", "M", "N", "P", "R", "S", "T", "V", "W", "X", "Y", "Z"];
      let digitos = valor ? valor.length : 0;
      let i = 0;
      let array = valor ? valor.split("") : 0;
      let key;
      let bool = true;
      while (i < digitos) {
        key = array[i].toUpperCase();
        if (i < 4) {
          if (!valid_numbers.includes(key)) {
            bool = false;
          }
        } else if (i >= 4 && i < 7) {
          if (!valid_letters.includes(key)) {
            bool = false;
          }
        }
        i++;
      }
      return bool;
    },
    validarFormulario() {
      return this.comprobarMatricula();
    },
    comprobarMatricula() {
      let matricula = this.formulario.matricula.join("");
      if (matricula.length == 7) {
        this.errors.matricula = false;
        document.getElementById("matricula").style.border = "1px solid green";
        return true;
      } else {
        this.errors.matricula = true;
        return false;
      }
    },
    getWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    matricula: function (new_val, old_val) {
      this.matricula = this.matricula.trimEnd();
      if (new_val.trim() === "") {
        this.matricula = " ";
      } else {
        if (new_val.trim().length > 7) {
          this.matricula = old_val;
        } else {
          if (new_val != " " && !this.validateAllMatricula(new_val.trim())) {
            this.matricula = old_val;
          } else {
            this.escribirMatricula();
          }
        }
      }
      this.focusInputMatricula();
    },
    windowWidth(value) {
      const menu = document.querySelector('#navigation_menu');
      if (value >= 992) {
        menu.style.display = 'block';
      }else{
        menu.style.display = 'none';
      }
    }
  },
  created: function () {
    window.dataLayer = window.dataLayer || []; //LLAMADA GOOGLE TAG
    
    window.dataLayer.push({
      event: "escribir_matricula"

    });
  },
  mounted: function () {
    //Set color de las bolitas que indican el cambio de paso
    store.commit("setBarraColor1", "blanco");
    store.commit("setCheckColor2", "blanco");
    store.commit("setBarraColor2", "blanco");
    store.commit("setCheckColor3", "blanco");
    this.matricula = this.formulario.matricula.length > 0 ? this.formulario.matricula.join("") : " ";
    const menu = document.querySelector('#navigation_menu');
    if (window.innerWidth >= 992) {
      menu.style.display = 'block';
    }else{
      menu.style.display = 'none';
    }
    window.addEventListener('resize', this.getWindowWidth);
  },
};
</script>

<!-- <style lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
@include media-breakpoint-only(md) {
  #navigation_menu {
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: none;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: none;
  }
}
</style> -->
<style scoped lang="scss">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";
#footer-mobile {
  // No queremos que se vea el footer de primeras en mobile, añadiremos un margin adaptado a cada pantalla del form
  margin-top: 75%;
}
.volver_circle {
  height: 35px;
  width: 35px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: solid 1px #dadada;
  border-radius: 50%; /* may require vendor prefixes */
  background: #fff;
}
@keyframes cambio_paso {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.cambio_paso {
  animation: cambio_paso 2s infinite;
}

#step1 {
  .contenedor {
    position: relative;
    text-align: center;
    color: white;
  }

  .centered {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #004680;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
  }

  #image_formulario {
    background-repeat: no-repeat;
    background-size: cover;
  }

  height: 100%;

  p.error {
    width: 80%;
    padding-top: 4px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0 !important;
  }

  #container_input_matricula {
    width: 80%;
    min-width: 320px;
    position: relative;
    margin-top: 15px;

    .container_spans {
      position: absolute;
      bottom: 10px;
      padding-left: 28px;
      width: 100%;
      display: flex;
      justify-content: center;

      span {
        font-size: 30px;
        border-bottom: 1px solid black;
        display: inline-block;
        margin: 0 5px;
        width: 35px;
        text-align: center;
      }
    }

    .matricula {
      width: 100%;
      padding: 0px 15px;
      height: 60px;
      font-size: 30px;
      color: white;
      border-radius: 6px;
      border: solid 1px rgba(0, 0, 0, 0.5);
      min-width: 320px;
      letter-spacing: 26px;
    }

    .placeholder_matricula {
      color: #aaaaaaaa;
    }

    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  #fourth_row_form {
    margin-top: 20px;
  }
}

@include media-breakpoint-only(xs) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }

    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }

    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 85px;
      max-height: 220px;
      background-color: #b3dcff;
    }
    #container_input_matricula {
      min-width: 320px;
      width: 100%;

      .container_spans {
        left: 0;
        width: calc(100% - 10px);
        padding-left: 28px;

        span {
          font-size: 32px;
          margin: 0 5px;
          width: 30px;
          text-align: center;
        }
      }

      .matricula {
        width: 100%;
        padding: 13px 0px;
        font-size: 30px;
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.5);
        padding-left: 0vw;
      }
    }
    #boton_continuar {
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .error {
    width: 100% !important;
  }
}

@include media-breakpoint-only(sm) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }

    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }

    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }

    #first_row {
      height: 86px;
      max-height: 220px;
      background-color: #b3dcff;
    }
    #container_input_matricula {
      min-width: 320px;
      width: 100%;

      .container_spans {
        left: 0;
        width: calc(100% - 10px);
        padding-left: 28px;

        span {
          font-size: 32px;
          margin: 0 5px;
          width: 30px;
          text-align: center;
        }
      }

      .matricula {
        width: 100%;
        padding: 13px 0px;
        font-size: 30px;
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.5);
        padding-left: 0vw;
      }
    }
    #boton_continuar {
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .error {
    width: 100% !important;
  }
}

@include media-breakpoint-only(md) {
  #step1 {
    .slogan1 {
      color: #004680;
      font-size: 16px;
      font-weight: 600;
    }

    .slogan2 {
      font-size: 18px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 325px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    #container_input_matricula {
      .matricula {
        width: 100%;
        padding: 13px 0px;
        font-size: 30px;
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.5);
        padding-left: 17vw;
      }
    }
  }
  .error {
    width: 100% !important;
  }
  #boton_continuar {
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

@include media-breakpoint-only(lg) {
  .margin-promo {
    margin-top: 145px !important;
  }
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .navigation_menu {
    display: none;
  }
  #step1 {
    margin-top: 90px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }

    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 375px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    #container_input_matricula {
      .matricula {
        width: 100%;
        padding: 13px 0px;
        font-size: 30px;
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.5);
        padding-left: 17vw;
      }
    }
  }
  #boton_continuar {
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .error {
    width: 100% !important;
  }
}

@include media-breakpoint-only(xl) {
  .margin-promo {
    margin-top: 145px !important;
  }
  .navigation_menu {
    display: none;
  }
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  #step1 {
    margin-top: 90px;

    .slogan1 {
      color: #004680;
      font-size: 20px;
      font-weight: 600;
    }

    .slogan2 {
      font-size: 22px;
      font-family: "Montserrat-Bold";
    }
    #image_formulario {
      margin-top: 90px;
      width: 100%;
      height: 525px;
    }
    #first_row {
      min-width: 320px;
      width: 100%;
      height: 97px;
      max-height: 220px;
      background-color: #b3dcff;
      // margin-top: 100px;
    }
    #container_input_matricula {
      .matricula {
        width: 100%;
        padding: 13px 0px;
        font-size: 30px;
        border-radius: 6px;
        border: solid 1px rgba(0, 0, 0, 0.5);
        padding-left: 17vw;
      }
    }
    #boton_continuar {
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .error {
    width: 100% !important;
  }
}
</style>
